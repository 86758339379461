<template>
  <div>
    <div class="account-pages mt-5 mb-5">
      <div class="container">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo () {
    return {
      link: [
        { hid: 'favorite-icon', rel: 'icon', href: '/favicon.png' , type: 'image/x-icon' }
      ]
    }
  }
}
</script>
